<template>

  <div>


    <div>
      <h3 style="text-align: center; font-size: 22px; padding-top: 28px; padding-bottom: 6px;">关于经济数据指标的维护
      </h3>





      <div style="padding-bottom: 6px; padding-top: 6px;">
        <el-row  type="flex">
          <el-col :span="5" :offset="3" >
            <div style="height:100%; padding-top: 3px; "> 
              <span   style=" font-size: 16px; height:100%;"  >省市&公司:</span> <span style=" font-size: 16px; height:100%; padding-left: 3px;">一个测试的省份</span> 
            </div>


          </el-col>

          <el-col :span="5">
            <span style=" padding-right: 3px;">开始年份:</span>
            <el-date-picker v-model="datefrom" type="year" placeholder="选择年" format="yyyy 年" value-format="yyyy">
            </el-date-picker></el-col>

          <el-col :span="5">
            <span style=" padding-right: 3px;">结束年份:</span>
            <el-date-picker v-model="dateto" type="year" placeholder="选择年" format="yyyy 年" value-format="yyyy">
            </el-date-picker></el-col>

          <el-col :span="2">
            <el-button type="primary" icon="el-icon-search" size="mini" @click="getModeltest"
              style="margin-left:12px;">查询</el-button></el-col>

        </el-row>
      </div>





    </div>


    <!-- 使用的是 Bootsraps 分割线 -->
    <hr class="bg-danger border-1 border-top border-primary">

    <div>
      <el-card>
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAddDetails">添加
        </el-button>
        <el-button type="warning" icon="el-icon-delete" size="mini" @click="handleDeleteDetails">删除
        </el-button>
        <!-- <el-button type="warning" icon="el-icon-delete" size="mini" @click="handleDeleteAllDetails">清空
    </el-button> -->
        <el-button type="primary" icon="el-icon-upload" size="mini" @click="handleSubmit">提交
        </el-button>
        <el-table :data="myTable" :row-class-name="rowClassName" @selection-change="handleSelectionChange"
          ref="tableRef">
          <el-table-column type="selection" align="center" />
          <!-- <el-table-column label="序号" align="center" prop="id" /> -->
          <el-table-column label="年份" align="left" prop="yearValue">
            <template slot-scope="scope">
              <el-date-picker type="year" placeholder="选择年" format="yyyy 年" value-format="yyyy"
                v-model="myTable[scope.row.id - 1].yearValue">
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column label="年末常住人口(万人)" prop="inputValue">
            <template slot-scope="scope">
              <el-input placeholder="请输入内容" v-model="myTable[scope.row.id - 1].inputValue1" clearable>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="国内生产总值(GDP)" prop="inputValue">
            <template slot-scope="scope">
              <el-input placeholder="请输入内容" v-model="myTable[scope.row.id - 1].inputValue2" clearable>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="城镇居民可支配收入" prop="inputValue">
            <template slot-scope="scope">
              <el-input placeholder="请输入内容" v-model="myTable[scope.row.id - 1].inputValue3" clearable>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="农民人均纯收入(元)" prop="inputValue">
            <template slot-scope="scope">
              <el-input placeholder="请输入内容" v-model="myTable[scope.row.id - 1].inputValue4" clearable>
              </el-input>
            </template>
          </el-table-column>


          <el-table-column label="社会消费品零售总额" prop="inputValue">
            <template slot-scope="scope">
              <el-input placeholder="请输入内容" v-model="myTable[scope.row.id - 1].inputValue5" clearable>
              </el-input>
            </template>
          </el-table-column>

        </el-table>
      </el-card>

    </div>

  </div>
</template>






<script>
export default {
  data() {
    return {
      // 详细list
      datefrom:'2018',
      dateto:'2023',
      myTable: [
    {
        "inputValue1": "1",
        "inputValue2": "2",
        "inputValue3": "3",
        "inputValue4": "4",
        "inputValue5": "5",
        "yearValue": "2018",
        "id": 1
    },
    {
        "inputValue1": "666",
        "inputValue2": "666",
        "inputValue3": "666",
        "inputValue4": "66",
        "inputValue5": "888",
        "yearValue": "2019",
        "id": 2
    },
    {
        "inputValue1": "333",
        "inputValue2": "444",
        "inputValue3": "555",
        "inputValue4": "666",
        "inputValue5": "999",
        "yearValue": "2020",
        "id": 3
    },
    {
        "inputValue1": "123.5",
        "inputValue2": "222",
        "inputValue3": "222",
        "inputValue4": "3333",
        "inputValue5": "444",
        "yearValue": "2021",
        "id": 4
    },
    {
        "inputValue1": "222",
        "inputValue2": "333",
        "inputValue3": "444",
        "inputValue4": "555",
        "inputValue5": "777",
        "yearValue": "2022",
        "id": 5
    },    
    {
        "inputValue1": "212",
        "inputValue2": "313",
        "inputValue3": "414",
        "inputValue4": "225",
        "inputValue5": "727",
        "yearValue": "2023",
        "id": 6
    }
],
      multipleSelection: [],
    }
  },
  created() {
    console.log('myTable: ' + this.myTable)
  },
  methods: {
    rowClassName({ row, rowIndex }) {
      row.id = rowIndex + 1
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log(val)
    },
    handleAddDetails() {
      if (this.myTable === undefined) {
        this.myTable = []
      }
      const obj = {

        inputValue1: '',
        inputValue2: '',
        inputValue3: '',
        inputValue4: '',
        inputValue5: '',
        yearValue: '2022',
      }
      this.myTable.push(obj)
    },
    // 多选删除
    handleDeleteDetails() {
      if (this.multipleSelection.length === 0) {
        this.$alert('请先选择要删除的数据', '提示', {
          confirmButtonText: '确定'
        })
      } else {
        var _this = this
        // 拿到选中的数据；
        var checkedData = this.multipleSelection
        // 如果选中数据存在
        if (checkedData) {
          // 将选中数据遍历
          checkedData.forEach(function (item, index) {
            // 遍历源数据
            _this.myTable.forEach(function (itemI, indexI) {
              // 如果选中数据跟元数据某一条标识相等，删除对应的源数据
              if (item.id === itemI.id) {
                _this.myTable.splice(indexI, 1)
              }
            })
          })
        }
        // 清除选中状态
        this.$refs.tableRef.clearSelection()
      }
    },
    handleDeleteAllDetails() {
      this.myTable = []
    },
    handleSubmit() {
      console.log(this.myTable)
    }
  }
}
</script>


<style scoped>

</style>